<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">班级培训统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">班级详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 class="flexcb" style="padding-bottom:20px;">
              <span>基本信息</span>
            </h3>
            <div>
              <el-form :inline="false"  label-width="10rem" class="formCell">
                <el-form-item label="班级名称："  prop="projectName">
                  <p>{{Form.projectName}}</p>
                </el-form-item>
                <el-form-item  label="行政区划：" >
                  <p>{{Form.areaNamePath }}</p>
                </el-form-item>
                <el-form-item  label="有效日期：" >
                  <p>{{Form.startDate | momentDate}} - {{Form.endDate | momentDate}}</p>
                </el-form-item>
                <el-form-item  label="报名截止时间：" >
                  <p>{{Form.deadDate | momentDate}}</p>
                </el-form-item>
                 <el-form-item  label="负责人：" >
                  <p>{{Form.projectUser}}</p>
                </el-form-item>
                  <el-form-item  label="负责人电话：" >
                   <p>{{Form.projectUserPhone}}</p>
                </el-form-item>
                  <el-form-item  label="培训类型：" >
                  <p>{{Form.Trainingtype}}</p>
                </el-form-item>
                  <el-form-item  label="培训人数：" >
                   <p>{{Form.projectPeople || '无'}}</p>
                </el-form-item>
                  <el-form-item label="培训地点：" >
                  <p>{{Form.projectAddress || '无'}}</p>
                </el-form-item>
                  <el-form-item label="期次：" >
                 <p>{{Form.projectPc || '无'}}</p>
                </el-form-item>
                  <el-form-item label="考试机构：" >
                   <p>{{Form.projectPaperComp || '无'}}</p>
                </el-form-item>
                  <el-form-item  label="考试时间：" >
                   <p v-if="Form.projectPaperDate">{{Form.projectPaperDate | momentDate || '无'}} </p>
                   <p v-else>无</p>
                </el-form-item>
                  <el-form-item  label="人群类型："  v-if="Form.projectCrowd">
                  <p  v-if="Form.projectCrowd">
                {{
                   $setDictionary("CROWDTYPE", Form.projectCrowd)
                }}</p>
                </el-form-item>
                 <el-form-item label="课程图片：" prop="thumbnail">
                  <div class="img-el-upload" style="width:256px;" v-if="Form.thumbnail">
                    <el-image
                      :src="Form.thumbnail || require('@/assets/develop.png')"
                      fit="contain"
                    ></el-image>
                  </div>
                  <div v-else>
                    <p>无</p>
                  </div>
              </el-form-item>
              </el-form>
            </div>
            <el-divider></el-divider>
            <h3 style="padding-bottom:20px;">基本设置</h3>
             <div>
              <el-form :inline="true" ref="ruleForm" label-width="12rem">
                   <el-form-item  label="允许学员查看学时证明：" >
                  <p>{{Form.projectClassHourCertificate == true ? '是' : '否'}}</p>
                </el-form-item>
              </el-form>
             </div>
          </div>
        </div>
      </div>
    </div>
     <el-dialog
          title="审核事由"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
          <div>
            <el-input
              type="textarea"
               maxlength="50"
               show-word-limit
              placeholder="请输入审核理由"
              v-model="auditReason">
            </el-input>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancle" class="bgc-bv">取 消</el-button>
            <el-button  @click="sure()" class="bgc-bv">确 定</el-button>
          </span>
        </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ClassTraingDetails",
  components: {
  },
  mixins: [List],
  data() {
    return {
      auditReason:"",
      projectId: this.$route.query.projectId,
      Form:{
          projectName: '',
          Trainingtype:'',
          areaNamePath:'',
          deadline: '',
          projectUser: '',
          projectUserPhone: '',
          projectPaperDate: '',
          projectPaperComp: '',
          projectPc: '',
          projectAddress: '',
          projectPeople: '',
          thumbnail: '',
          projectCrowd:'',
          fileKey: '',
          projectClassHourCertificate:''
      },
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.projectId =  this.$route.query.projectId
      this.loadData()
    },
    loadData() {
      this.$post("/biz/project/query", { projectId: this.projectId})
        .then(ret => {
          let resData = ret.data;
          this.Form = {
            projectId: resData.projectId,
            projectName: resData.projectName,
            areaNamePath: resData.areaNamePath,
            startDate:resData.startDate,
            endDate:resData.endDate,
            deadDate: resData.signEndDate,
            projectUser: resData.projectUser,
            projectUserPhone: resData.projectUserPhone,
            projectPaperDate: resData.projectPaperDate,
            projectPaperComp: resData.projectPaperComp,
            projectPc: resData.projectPc,
            projectAddress: resData.projectAddress,
            projectPeople: resData.projectPeople,
            thumbnail: resData.applicationFormUrl,
            projectCrowd: resData.projectCrowd,
            fileKey: resData.applicationForm,
            projectClassHourCertificate: resData.projectClassHourCertificate,
          };
          let areaNamePath = this.Form.areaNamePath.replace(/,/g,'-');
          this.Form.areaNamePath = areaNamePath;
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (resData.codeBo.trainTypeNamePath) {
            name1 = resData.codeBo.trainTypeNamePath;
          }
          if (resData.codeBo.postName) {
            name2 = "/" + resData.codeBo.postName;
          }
          if (resData.codeBo.industryNamePath) {
            name3 = "/" + resData.codeBo.industryNamePath;
          }
          if (resData.codeBo.occupationNamePath) {
            name4 = "/" + resData.codeBo.occupationNamePath;
          }
          if (resData.codeBo.trainLevelName) {
            name5 = "/" + resData.codeBo.trainLevelName;
          }
          let name = name1 + name2 + name3 + name4 + name5;
          name = name.replace(/,/g,'-');
          this.Form.Trainingtype = name;
        })
        .catch(err => {
          return;
        });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.formCom {
  .formCell {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
    }
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.btn-box {
  padding: 50px 0 0;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
}
</style>
<style lang="less"></style>
